/**
 * Direttiva che costruisce l'elemento di una lista
 */

export interface IItemListDirectiveScope extends ng.IScope {
	goToItemDetail: Function;
	chartOptions: any;
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	itemType: any;
	hourValue: any;
	isLearningPlan: boolean;
	isProject: boolean;
	isSection: boolean;
	percentageItem: any;
	suggestedPerson: any;
	suggestedFromAdmin: any;
	hasSuggested: any;
	globalApplicationData: any;
	libraryApplicationData: any;
	item: any;
	isItemAlreadyCertified: boolean;
	currentItemType: any;
	currentItem: any;
	isMandatory: boolean;
	mandatory: number;
	propedeuticReferenceId: string;
	isPropedeutic: boolean;
	hasPrerequisites: boolean;
	changePrerequisites: Function;
	propedeuticReferenceTitle: Function;
	propedeuticTitle: string;
	setPropedeuticOffCb: Function;
	prerequisites: any;
	propedeuticReferenceIds: Array<string>;
	referenceId: string;
	checkPropedeuticReferenceIds: Function;
	isScorm: boolean;
	isConsumed: boolean;
	cardCover: any;
	isItemDisabled: any;
	fullItem: any;
	page: any;
	isNotDisabled: any;
	adminMode: boolean;
	containerClass: string;
	opacityLayerClass: string;
	showPropedeuticalItem: Function;
	showPropedeuticalItemToThis: Function;
	isFirstCheckboxVisible: boolean;
	isSecondCheckboxVisible: boolean;
	iconType: any;
	subIconType: string;
	bgDisabledClass: string;
	textDisabledClass: string;
	showHourValue: boolean;
	isHourValueValorised: boolean;
	isCompleted: boolean;
	isItemContainer: boolean;
	percentageDescription: string;
	textWhiteDisabledClass: string;
	isRequired: boolean;
	isNotRequired: boolean;
	checkIfRequired: Function;
	checkIfNotRequired: Function;
	isLearningPlanEditingMode: any;
	isDetailBtnVisible: boolean;
	subtype: any;
	forceDisable: boolean;
}
angular.module('app').directive("itemList", ($window, $state, LibraryApplicationData, GlobalApplicationData, moment, $stateParams, $sessionStorage, $translate) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			item: '=', // Oggetto interno contenente tutte le informazioni della riga che sto andando a costruire
			selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '=',
			toggleSuggestedPerson: '=',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			propedeuticReferenceId: '=', // id dell'oggetto propedeutico per quello attuale
			propedeuticReferenceTitle: '&', // Titolo della referenza propedeutica
			mandatory: '=', // obbligatorietà
			managePrerequisites: '=',
			propedeuticReferenceIds: '=', // lista dei propedeuticReferenceIds di ogni itemChilds 
			showPropedeuticalItemToThis: '=', // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
			currentItemType: '=',
			currentItem: '=',
			isNotDisabled: '=',
			forceDisable: '='
		},
		link: link,
		templateUrl: 'app/shared/itemList/itemList.html'
	};
	function link($scope: IItemListDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta 
		// di un childItem
		if (!$scope.item.referenceId || !$scope.item.childObject) {
			$scope.card = $scope.item;
		} else {
			// Prima di salvare il childObject, recupero l'referenceId affinchè, in seguito, possa verifica se tale oggetto è propedeutico per qualche altro
			$scope.referenceId = $scope.item && $scope.item.referenceId;
			$scope.fullItem = $scope.item;
			$scope.card = $scope.item.childObject;
			$scope.item = $scope.item.childObject;
		}

		// Classi CSS che andranno nel contenitore
		$scope.containerClass = '';

		// Verifica se è un oggetto contenitore
		$scope.isItemContainer = false;

		// Classe CSS aggiunta al testo quando l'Item è disabilitato
		$scope.textWhiteDisabledClass = '';

		// Verifica se c'è almeno o l'ora o i minuti nel monte ore
		$scope.isHourValueValorised = false;

		// Verifica se mostrare il monte ore
		$scope.showHourValue = false;

		// Classe CSS dell'opacity-layer (aggiunta solo se l'item è disabilitato)
		$scope.opacityLayerClass = '';

		// Descrizione sotto la percentuale
		$scope.percentageDescription = null;

		// Verifica se sono nella parte amministrativa della Library
		$scope.adminMode = $sessionStorage.adminMode;

		// Classe CSS aggiunta allo sfondo quando l'Item è disabilitato
		$scope.bgDisabledClass = '';

		// Verifica se è completato
		$scope.isCompleted = false;

		// Classe CSS aggiunta al testo quando l'Item è disabilitato
		$scope.textDisabledClass = '';

		// Immagine di copertina
		$scope.cardCover = null;

		// Icona per la tipologia di oggetto
		$scope.iconType = null;

		// Scritta sotto l'icona della tipologia di oggetto
		$scope.subIconType = null;

		// Controllo se non è obbligatorio
		$scope.checkIfNotRequired = () => {
			if ($scope.isPropedeutic || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isNotRequired = true;
			} else {
				$scope.isNotRequired = false;
			}
		}

		// Verifico se questo oggetto è propedeutico per qualche altro oggetto
		$scope.isPropedeutic = false;
		$scope.checkPropedeuticReferenceIds = () => {
			if ($scope.propedeuticReferenceIds && $scope.propedeuticReferenceIds.length) {
				for (let m = 0; m < $scope.propedeuticReferenceIds.length; m++) {
					if ($scope.referenceId && $scope.propedeuticReferenceIds[m] == $scope.referenceId) {
						$scope.isPropedeutic = true;
						break;
					} else {
						$scope.isPropedeutic = false;
					}
				}
			} else {
				$scope.isPropedeutic = false;
			}
			$scope.checkIfNotRequired();
		}
		if ($scope.propedeuticReferenceIds) {
			$scope.checkPropedeuticReferenceIds();
		}

		$scope.checkIfNotRequired();

		// Verifico se esiste un altro oggetto propedeutico per quello attuale
		$scope.prerequisites = {
			hasPrerequisites: $scope.propedeuticReferenceId ? true : false
		}

		// Callback che, se ho chiuso la modale prima di selezionare un oggetto a cui aggiugnere la propedeuiticità, spenge lo switch
		$scope.setPropedeuticOffCb = () => {
			$scope.prerequisites.hasPrerequisites = false;
		}

		// Se ho un item propedeutico a quello attuale, recupero il suo titolo
		if ($scope.prerequisites.hasPrerequisites) {
			$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
		}

		// Quando cambia l'oggetto propedeutico di quello attuale, devo recuperare il suo titolo
		$scope.$watch('propedeuticReferenceId', (newValue, oldValue) => {
			if (newValue) {
				$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
			}
		}, true);

		// Quando cambia la lista degli oggetti propedeutici, devo aggiornare le etichette di obbligatorietà poichè l'item attuale potrebbe essere diventato
		// un item propedeutico a qualcos'altro
		$scope.$watch('propedeuticReferenceIds', (newValue, oldValue) => {
			if (newValue) {
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Salvo l'obbligatorietà
		$scope.isMandatory = !!$scope.mandatory;

		// Configurazione della percentuale
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff',
			lineWidth: 2,
			lineCap: 'butt',
			size: 35
		}

		// Verifico se è un oggetto scorm
		$scope.isScorm = $scope.card && $scope.card.itemType && ($scope.card.itemType == LibraryApplicationData.constants.SCORM_FREE || $scope.card.itemType == LibraryApplicationData.constants.SCORM_INVITE) ? true : false;

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Sottotipo
		$scope.subtype = null;

		// Valore formativo
		$scope.hourValue = null;

		// Verifica se l'item è stato completato
		$scope.isConsumed = false;

		// Persone che hanno suggerito l'elemento
		$scope.suggestedPerson = [];

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se è un progetto
		$scope.isProject = false;

		// Verifica se è una sezione
		$scope.isSection = false;

		// Verifica se ha suggeritori
		$scope.hasSuggested = false;

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;

		$scope.windowWidth = $window.innerWidth;
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.card && $scope.card.title.length < 62) {
			$scope.card.titleClass = 'card-title-h3';
		} else if ($scope.card && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
			$scope.card.titleClass = 'card-title-h2';
		}

		// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
		angular.element($window).bind('resize', () => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 680 && $scope.card && $scope.card.title.length < 62) {
				$scope.card.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 680 && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
				$scope.card.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h2';
			}
		});
		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if ($scope.windowWidth < 680 && $scope.card && $scope.card.title.length < 62) {
				$scope.card.titleClass = 'card-title-h6';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h3';
			}

			if ($scope.windowWidth < 680 && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
				$scope.card.titleClass = 'card-title-h5';
			} else if ($scope.isWidthExceeded == true) {
				$scope.card.titleClass = 'card-title-h2';
			}
		});

		// Verifico gli attributi dell'oggetto
		if ($scope.card) {
			if ($scope.card.itemAttributes) {
				let itemAttributes = $scope.card.itemAttributes;
				for (let k = 0; k < itemAttributes.length; k++) {
					// Verifico se c'è l'immagine di copertina
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.CARD_COVER.ITEM_TYPE) {
						$scope.cardCover = itemAttributes[k].attributeValue;
					}

					// Verifico il tipo di oggetto
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.OBJECT_TYPE) {
						$scope.itemType = itemAttributes[k].attributeValue;
						if ($scope.itemType == LibraryApplicationData.constants.LEARNING_PLAN) {
							$scope.isLearningPlan = true;
						} else if (itemAttributes[k].attributeValue == LibraryApplicationData.constants.PROJECT) {
							$scope.isProject = true;
						} else if (itemAttributes[k].attributeValue == LibraryApplicationData.constants.SECTION) {
							$scope.isSection = true;
						}
					}

					// Verifico se ha il Sottotipo
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.OBJECT_TYPE_SPECIALIZATION) {
						$scope.subtype = itemAttributes[k].attributeValue;
					}

					// Verifico se c'è il valore formativo
					if (itemAttributes[k].attributeType == LibraryApplicationData.constants.VALUE) {
						let duration = moment.duration(parseInt(itemAttributes[k].attributeValue), 'seconds');
						$scope.hourValue = {
							hours: duration.minutes(),
							minutes: duration.seconds()
						}
					}
				}

				// Se non ho trovato il sottotipo, lo setto di default come la tipologia dell'oggetto
				if (!$scope.subtype) {
					if ($scope.itemType) {
						$scope.subtype = $scope.itemType;
					} else if (!$scope.itemType && $scope.isScorm) {
						$scope.subtype = LibraryApplicationData.constants.SCORM;
					}
				}

				// Se non c'è l'immagine della card ne metto una di default
				if (!$scope.cardCover) {
					$scope.cardCover = '../assets/img/img-card.png'
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.fullItem && $scope.fullItem.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.fullItem.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.card && $scope.card.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.card.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			if ($scope.card.engagements) {
				// Data dell'evento EVENT_ITEM_STARTED
				let dateEventItemStarted: any = null;
				// Data dell'evento EVENT_ITEM_CONSUMED
				let dateEventItemConsumed: any = null;
				// Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
				let percentageEventItemStarted: any = null;

				// Inizio a scorrermi tutti gli engagement
				for (let d = 0; d < $scope.card.engagements.length; d++) {
					let currentEngagement = $scope.item.engagements[d];

					if (currentEngagement.moduleName == LibraryApplicationData.constants.ITEM) {
						// Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
							// Item concluso. Salvo la data in cui è stato creato tale engagement
							dateEventItemConsumed = currentEngagement.creationDate;
							// L'item è stato concluso
							$scope.isConsumed = true;
						}

						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED && currentEngagement.engagementDetails) {
							// Item iniziato. Salvo la data in cui è stato creato tale engagement e la percentuale
							dateEventItemStarted = currentEngagement.creationDate;
							for (let z = 0; z < currentEngagement.engagementDetails.length; z++) {
								let currentEngagementDetail = currentEngagement.engagementDetails[z];
								if (!hasItemRegistration && !hasScormRegistration && currentEngagementDetail.detailKey == LibraryApplicationData.constants.PERCENTAGE) {
									percentageEventItemStarted = currentEngagementDetail.detailValue;
									if (isNaN(percentageEventItemStarted)) {
										percentageEventItemStarted = "0";
									}
									break;
								}
							}
						}

						// Verifico se l'oggetto è già stato verificato
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
							$scope.isItemAlreadyCertified = true;
						}
					}
				}

				// Se ho sia l'engagement STARTED che CONSUMED, devo recuperare il più recente
				if (!hasScormRegistration && !hasItemRegistration) {
					if (dateEventItemStarted && dateEventItemConsumed) {
						let startedTime = new Date(dateEventItemStarted).getTime();
						let consumedTime = new Date(dateEventItemConsumed).getTime();
						if (startedTime > consumedTime) {
							// E' più recente l'evento started, quindi visualizzo a front-end la sua percentuale
							$scope.percentageItem = percentageEventItemStarted;
						} else {
							// Altrimenti la setto io al 100%
							$scope.percentageItem = "100";
						}
					} else if (dateEventItemStarted && !dateEventItemConsumed) {
						$scope.percentageItem = percentageEventItemStarted;
					} else if (!dateEventItemStarted && dateEventItemConsumed) {
						$scope.percentageItem = "100";
					} else {
						$scope.percentageItem = "0";
					}
				}
			}

			if ($scope.card.itemVisibilities) {
				// Ordino le visibilities per data
				$scope.card.itemVisibilities.sort((a: any, b: any) => {
					if (a.creationDate > b.creationDate) {
						return -1;
					} else if (a.creationDate < b.creationDate) {
						return 1;
					} else {
						return 0;
					}
				});

				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for (let j = 0; j < $scope.card.itemVisibilities.length; j++) {
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if ($scope.card.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION && $scope.card.itemVisibilities[j].fromAdmin == true) {
						$scope.suggestedFromAdmin = true;
						$scope.hasSuggested = true;
						break;
					} else if ($scope.card.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION) {
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for (let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.card.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if (groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.card.itemVisibilities[j].allowedUserId)) {
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson.push($scope.card.itemVisibilities[j]);
							break;
						}
					}
				}
			}
		}

		$scope.isItemDisabled = false;
		if ($scope.forceDisable || (
			$scope.propedeuticReferenceId && !$scope.isNotDisabled && $scope.page != 'editLearningPlanAdmin' && $scope.page != 'editSection' && $scope.page != 'editProject')) {
			$scope.isItemDisabled = true;
		}

		// Verifico se la prima checkbox è visibile 
		if (($scope.isLearningPlanEditingMode && $scope.page === 'editLearningPlan') || ($scope.isLearningPlanEditingMode && $scope.page === 'editLearningPlanAdmin') || ($scope.isLearningPlanEditingMode && $scope.page === 'editSection') || $scope.page === 'editSection') {
			$scope.isFirstCheckboxVisible = true;
		} else {
			$scope.isFirstCheckboxVisible = false;
		}

		// Verifico se la seconda checkbox è visibile 
		if (($scope.isLearningPlanEditingMode && $scope.page === 'addItemToCurrentLearningPlan') || $scope.page === 'addItemToCurrentSection') {
			$scope.isSecondCheckboxVisible = true;
		} else {
			$scope.isSecondCheckboxVisible = false;
		}

		// Calcolo le classi CSS
		if (!$scope.isItemDisabled) {
			$scope.containerClass += ' cursor-default';
		} else {
			if ($scope.forceDisable) {
				$scope.containerClass += ' cursor-default';
			} else {
				$scope.containerClass += ' cursor-pointer';
			}
			$scope.containerClass += ' itemDisabled';
			$scope.opacityLayerClass = 'opacity-layer';
			$scope.bgDisabledClass = 'bg-disable';
			$scope.textDisabledClass = 'text-disable';
			$scope.textWhiteDisabledClass = 'text-white';
		}

		// Verifico la tipologia di oggetto per aggiungere l'icona e la traduzione
		if ($scope.subtype == LibraryApplicationData.constants.MAGAZINE) {
			$scope.iconType = "import-contacts";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.MAGAZINE');
		} else if ($scope.subtype == LibraryApplicationData.constants.DVD) {
			$scope.iconType = "album";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.DVD');
		} else if ($scope.subtype == LibraryApplicationData.constants.BOOK) {
			$scope.iconType = "library-books";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.BOOK');
		} else if ($scope.isLearningPlan) {
			$scope.iconType = "book";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PLAYLIST');
		} else if ($scope.isProject) {
			$scope.iconType = "collections-bookmark";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PROJECT');
		} else if ($scope.isSection) {
			$scope.iconType = "collections-bookmark";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.SECTION');
		} else if ($scope.subtype == LibraryApplicationData.constants.PODCAST) {
			$scope.iconType = "library-music";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PODCAST');
		} else if ($scope.subtype == LibraryApplicationData.constants.GRAPH) {
			$scope.iconType = "featured-play-list";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.GRAPH');
		} else if ($scope.subtype == LibraryApplicationData.constants.DOCUMENT) {
			$scope.iconType = "description";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.DOCUMENT');
		} else if ($scope.subtype == LibraryApplicationData.constants.EBOOK) {
			$scope.iconType = "phone-android";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.EBOOK');
		} else if ($scope.isScorm && ($scope.subtype == LibraryApplicationData.constants.ELEARNING || $scope.subtype == LibraryApplicationData.constants.SCORM)) {
			$scope.iconType = "dvr";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.ELEARNING');
		} else if ($scope.subtype == LibraryApplicationData.constants.VIDEO) {
			$scope.iconType = "video-library";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.VIDEO');
		} else if ($scope.subtype == LibraryApplicationData.constants.IMAGE) {
			$scope.iconType = "insert-photo";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.IMAGE');
		} else if ($scope.subtype == LibraryApplicationData.constants.CERTIFICATE_ITEM) {
			$scope.iconType = "grade";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.CERTIFICATE_ITEM');
		}

		// Mostra l'oggetto propedeutico
		$scope.showPropedeuticalItem = () => {
			if ($scope.forceDisable) {
				return;
			}
			if ($scope.isItemDisabled) {
				$scope.showPropedeuticalItemToThis($scope.fullItem);
			}
		}

		// Verifico se è completato
		if ($scope.isConsumed && !$scope.isItemAlreadyCertified) {
			$scope.isCompleted = true;
		}

		// Calcolo le classi che andranno aggiunte al contenitore dell'intera card
		if ($scope.isProject || $scope.isLearningPlan || $scope.isSection) {
			// Salvo il fatto che è un oggetto contenitore
			$scope.isItemContainer = true;
		}

		// Traduco il testo sotto la percentuale
		if ($scope.isItemContainer) {
			$scope.percentageDescription = $translate.instant('card.types.ADVANCEMENT');
		} else {
			$scope.percentageDescription = $translate.instant('card.types.ADVANCEMENT_OF_ITEMS');
		}

		// Verifica se c'è o l'ora o i minuti nel monte ore
		if ($scope.hourValue && ($scope.hourValue.hours != 0 || $scope.hourValue.minutes != 0)) {
			$scope.isHourValueValorised = true;
		}

		// Verifico se mostrare il monte ore
		if ($scope.adminMode || $scope.isHourValueValorised) {
			$scope.showHourValue = true;
		}

		// Controllo se l'oggetto è obbligatorio
		$scope.checkIfRequired = () => {
			if ($scope.mandatory == 1 || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isRequired = true;
			} else {
				$scope.isRequired = false;
			}
		}

		$scope.checkIfRequired();

		// Verifico se far vedere il pulsante per andare nel dettaglio dell'oggetto
		if ($scope.isItemDisabled || $scope.isLearningPlanEditingMode) {
			$scope.isDetailBtnVisible = true;
		} else {
			$scope.isDetailBtnVisible = false;
		}

		// Porta alla pagina di dettaglio dell'oggetto selezionato
		$scope.goToItemDetail = (itemId: string) => {
			// Se l'id passato alla funzione è diverso dall'id dell'oggetto Item significa che sto aprendo
			// la card di un elemento suggerito all'interno di un Item (es Learning Plan)
			if (itemId != $scope.card.itemId) {
				if ($scope.itemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.card.itemId });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.SECTION) {
					$state.go("app.libraryApp.itemDetailSec", { projectId: $stateParams.projectId, sectionId: $stateParams.itemId, itemId: $scope.card.itemId });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					// Sto accedendo a un oggetto contenuto in un learning plan
					$state.go("app.libraryApp.itemDetailLp", { lpId: $stateParams.itemId, itemId: $scope.card.itemId });
				} else {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.card.itemId });
				}

			} else {
				// Se è un progetto, vado nel dettaglio del progetto
				if ($scope.itemType == $scope.libraryApplicationData.constants.PROJECT) {
					$state.go("app.libraryApp.projectDetail", { itemId: itemId, breadcrumbs: null });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.libraryApp.itemDetail", { itemId: itemId });
				} else {
					$state.go("app.libraryApp.itemDetail", { itemId: itemId });
				}
			}
		}
	}
});